export default {
  en: {
    title: "",
  },
  pl: {
    heroHome: {
      subtitle: "<span>48h godzin</span> by zmienić świat",
      description: `Weź udział w technologiczno-ekologicznym hackathonie - ucz się, działaj i wspieraj naszą planetę! Bądź częścią zmiany!`,
      date: "27-29 czerwca 2025 / Gdańsk",
      button: "Weź udział!  &rarr;",
    },
    hero2022: {
      subtitle: "Edycja <span>2022</span>",
      description: `Sprawdź jak wyglądała poprzednia<br />edycja hackathonu!`,
      date: "10-12 czerwca 2022 / Gdańsk / Online",
      button: "Skontaktuj się!  &rarr;",
    },
    hero2023: {
      subtitle: "Edycja <span>2023</span>",
      description: `Sprawdź jak wyglądała poprzednia<br />edycja hackathonu!`,
      date: "2-4 czerwca 2023 / Gdańsk",
      button: "Skontaktuj się!  &rarr;",
    },
    hero2024: {
      subtitle: "Edycja <span>2024</span>",
      description: `Sprawdź jak wyglądała poprzednia<br />edycja hackathonu!`,
      date: "21-23 czerwca 2024 / Gdańsk",
      button: "Skontaktuj się!  &rarr;",
    },
  },
}
